@import "./ColorFont.scss";

#header {
  //.navbar, header{ background-color: $rose-pale3 !important}
  .navbar,
  header {
    background-color: $rose-pale3;
  }

  // couleur du burger
  //.navbar-light .navbar-toggler-icon{ background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(234, 81, 82)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  .navbar-light .navbar-toggler-icon[data-burger="open"] {
    background-image: url("../Images/burger-vagin-open.svg");
  }
  .navbar-light .navbar-toggler-icon[data-burger="close"] {
    background-image: url("../Images/burger-vagin-close.svg");
  }

  // raz icon burger bootstrap
  .navbar-toggler-icon {
    padding: 0;
    width: 63px;
    height: 78px;
  }
  .navbar-toggler {
    outline: none;
    border: none;
    padding: 0;
  }
  #image-nav {
    display: none;
  }

  @media screen and (max-width: 992px) {
    #image-nav {
      display: flex;
      width: 205px;
      margin-top: 50px !important;
    }
    .navbar-nav {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    .nav-link {
      margin-right: 0 !important;
    }

    #livre {
      margin-top: -130px;
    }
  }

  .navbar-nav {
    // texte lien
    .nav-link {
      margin-right: 1.2em;
      font-family: LearningCurve;
      font-size: 40px;
      padding: 0;
    }

    .nav-link:last-child {
      margin-right: 200px;
    }

    // cercle autour des liens (hover)
    svg {
      position: absolute;
      stroke-dasharray: 550;
      stroke-dashoffset: 550;
      transition: all 0.4s ease-out;
    }

    // au hover sur les liens,
    .svg-active {
      stroke-dashoffset: 0;
    }

    // cerlce autour du lien du component affiché
    .component-active {
      stroke-dashoffset: 0;
    }

    // position des cercles
    .nav-link:nth-child(1) svg {
      width: 150px;
      transform: translate(-18%, -4%);
    }

    .nav-link:nth-child(2) svg {
      width: 200px;
      transform: translate(-16%, -4%);
    }

    .nav-link:nth-child(3) svg {
      width: 220px;
      transform: translate(-13%, -4%);
    }
  }
}
