/************* TYPOGRAPHIES ************/
@font-face {
  font-family: "AnotherShabby";
  src: url("./Typographie/Anothershabby.ttf");
}

@font-face {
  font-family: "CutiveMono";
  src: url("./Typographie/CutiveMono.ttf");
}

@font-face {
  font-family: "LearningCurve";
  src: url("./Typographie/LearningCurve.ttf");
}

@font-face {
  font-family: "AlertNotice";
  src: url("./Typographie/AlertNotice.ttf");
}

/*************** COULEURS **************/
$vert: #005221;
$rose: #eebebe;
$rose-fonce: #cf393a;
$rose-pale: #e3cfc9;
$rose-pale2: #f9e9e4;
$rose-pale3: #f8f2ee;
$marron: #381d12;
$orange: #e8903f;
$bordeaux: #930b0b;
$beige: #e9daca;

/********* PARAM SCROLLBAR & SELECT *******/
html {
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: $rose-pale3;
}
::-webkit-scrollbar-thumb {
  background: $rose;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $rose-fonce;
}

*::selection {
  background-color: $rose;
  color: #fff;
}

/************ LAYOUT BASE  **********/

// scroll vertical
@media screen and (max-width: 992px) {
  .outer-wrapper {
    width: 100vw;
    height: calc(100vh - 86px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  #slide {
    width: 100vw;
    padding: 12vw 0;
  }

  #slide:nth-child(1) {
    height: auto;
    padding: 0 0 12vw 0;
  }
  #slide:nth-child(2) {
    height: auto;
  }
  #slide:nth-child(3) {
    height: auto;
  }
  #slide:nth-child(6) {
    height: auto;
  }
  #slide:last-child {
    height: auto;
    padding: 0 !important;
  }

  // arc
  #slide::before {
    content: "";
    position: absolute !important;
    width: 50vw;
    height: 30vw;
    border-radius: 100%;
    background-color: red !important;
    display: none !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

// scroll horizontal
@media screen and (min-width: 992px) {
  .outer-wrapper {
    width: 100vh;
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }

  #slide {
    width: 100vw;
    height: calc(100vh - 86px);
  }

  #slide:nth-child(6) {
    width: auto !important;
  }

  // arc
  #slide::before {
    content: "";
    position: absolute;
    width: 4%;
    height: 50%;
    border-radius: 100%;
    background-color: red;
    transform-origin: center;
    align-self: flex-start;
    transform: translate(-35%, 0);
  }
}

strong {
  font-weight: normal;
}

/************ STYLE LOADER **********/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9e9e4;
  width: 100vw;
  height: 100vh;

  // titre mdv
  h1 {
    font-family: "AnotherShabby";
    font-size: 90px;
    margin: 0;
    color: #9c3939;
  }
  // text chargement
  p {
    font-family: "cutiveMono";
    font-size: 20px;
    color: #9c3939;
  }

  @media screen and (max-width: 700px) {
    h1 {
      font-size: 70px;
      width: 80%;
      text-align: center;
    }
  }
}
