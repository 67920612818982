@import "./ColorFont.scss";

#main-ConnaisToi {
  // couleur des sections et ::before
  #slide:nth-child(1) {
    background-image: url(../Images/feuille-pc.png);
    background-position: left center;
    background-size: auto 100%;
    background-repeat: repeat;
  }
  #slide:nth-child(1)::before {
    display: none;
  }
  #slide:nth-child(2),
  #slide:nth-child(2)::before {
    background-color: #000;
  }
  #slide:nth-child(3),
  #slide:nth-child(3)::before {
    background-color: $bordeaux;
  }
  #slide:nth-child(4),
  #slide:nth-child(4)::before {
    background-color: $rose-pale;
  }
  #slide:nth-child(5),
  #slide:nth-child(5)::before {
    background-color: $marron;
  }
  #slide:nth-child(6),
  #slide:nth-child(6)::before {
    background-color: #d5d7d3;
  }

  #slide:nth-child(4) {
    padding-bottom: 0;
  }

  .coll-text {
    z-index: 10;
  }

  #container1 {
    // position des strates de vuvle
    .colImg img {
      width: 100%;
      position: absolute;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    .colImg img:nth-child(1) {
      position: relative;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    // position div avec label
    .row-label {
      position: absolute;
    }

    //nom anatomie
    .label {
      color: #fff;
      font-family: "AnotherShabby";
      font-size: 19px;
      background-image: url(../Images/scotch2.png);
      background-size: 100% 100%;
      width: 200px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 300;
      cursor: pointer;
    }

    // label "découvre a quoi je ressemble"
    .label-clitoris,
    .label-vagin {
      position: absolute;
      color: $rose-fonce;
      font-family: "LearningCurve";
      font-size: 40px;
      line-height: 0.7em;
      text-align: center;
      transform: translate(100px, -60px) rotate(8deg) scale(1);
      z-index: 400;
      opacity: 0;
      transition: all 1s ease;
    }
    .label-clitoris:hover,
    .label-vagin:hover {
      transform: translate(100px, -60px) rotate(8deg) scale(1.1);
    }
    .label-clitoris-visible,
    .label-vagin-visible {
      opacity: 1;
    }

    // arrow
    .svg {
      position: absolute;
      stroke-dasharray: 550;
      stroke-dashoffset: 550;
      transition: all 0.6s ease-out;
    }

    // position arrow vulve
    .svg-label1 {
      transform: translate(200px, -100px) rotate(-15deg);
    }
    .svg-label1 {
      height: 210px;
      width: 350px;
    }
    .svg-label1 path {
      stroke-width: 1;
    }
    .svg-label2 {
      transform: translate(215px, -55px) rotate(15deg);
    }
    .svg-label3 {
      transform: translate(-235px, -55px) rotate(2deg);
    }
    .svg-label4 {
      transform: translate(-270px, 40px) rotate(-22deg);
    }
    .svg-label5 {
      transform: translate(215px, -165px) rotate(12deg);
    }
    .svg-label6 {
      transform: translate(-255px, -70px) rotate(-25deg);
    }
    .svg-label7 {
      transform: translate(100px, -130px);
    }
    .svg-label8 {
      transform: translate(-250px, -180px) rotate(-15deg);
    }
    .svg-label9 {
      transform: translate(-230px, 0) rotate(-20deg);
    }

    // position arrow vulve
    .svg-label2-2 {
      width: 210px;
      transform: translate(210px, -45px) rotate(18deg);
    }
    .svg-label3-2 {
      width: 120px;
      transform: translate(-220px, -100px) rotate(20deg);
    }
    .svg-label3-2 path {
      stroke-width: 3.5;
    }
    .svg-label6-2 {
      width: 210px;
      transform: translate(-220px, -100px) rotate(-5deg);
    }
    .svg-label6-2 path {
      stroke-width: 2;
    }
    .svg-label7-2 {
      transform: translate(120px, -130px);
    }
    .svg-label8-2 {
      width: 250px;
      transform: translate(-245px, -180px) rotate(-15deg);
    }
    .svg-label8-2 path {
      stroke-width: 2.5;
    }

    .svg-active {
      stroke-dashoffset: 0;
    }

    // row "annatomie d'une vulve"
    .last-row {
      font-size: 40px;
      font-family: "LearningCurve";
      color: $rose-fonce;
    }

    //
    .svg-last-row {
      position: absolute;
      transform: translate(-27px, -10px);
    }

    // anim shake vulve
    .image-shake {
      -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: rotate(0);
      transform-origin: center;
    }

    //anim shake +992px
    @keyframes shake {
      10%,
      90% {
        transform: rotate(-0.5deg) translateX(-100%);
        -ms-transform: rotate(-0.5deg) translateX(-100%);
        -webkit-transform: rotate(-0.5deg) translateX(-100%);
        -moz-transform: rotate(-0.5deg) translateX(-100%);
        -o-transform: rotate(-0.5deg) translateX(-100%);
      }

      20%,
      80% {
        transform: rotate(0.6deg) translateX(-100%);
        -ms-transform: rotate(0.6deg) translateX(-100%);
        -webkit-transform: rotate(0.6deg) translateX(-100%);
        -moz-transform: rotate(0.6deg) translateX(-100%);
        -o-transform: rotate(0.6deg) translateX(-100%);
      }

      30%,
      50%,
      70% {
        transform: rotate(-0.5deg) translateX(-100%);
        -ms-transform: rotate(-0.5deg) translateX(-100%);
        -webkit-transform: rotate(-0.5deg) translateX(-100%);
        -moz-transform: rotate(-0.5deg) translateX(-100%);
        -o-transform: rotate(-0.5deg) translateX(-100%);
      }

      40%,
      60% {
        transform: rotate(0.6deg) translateX(-100%);
        -ms-transform: rotate(0.6deg) translateX(-100%);
        -webkit-transform: rotate(0.6deg) translateX(-100%);
        -moz-transform: rotate(0.6deg) translateX(-100%);
        -o-transform: rotate(0.6deg) translateX(-100%);
      }
    }

    //anim shake -992px
    @media screen and (max-width: 992px) {
      @keyframes shake {
        10%,
        90% {
          transform: rotate(-0.5deg) translateY(-100%);
          -ms-transform: rotate(-0.5deg) translateY(-100%);
          -webkit-transform: rotate(-0.5deg) translateY(-100%);
          -moz-transform: rotate(-0.5deg) translateY(-100%);
          -o-transform: rotate(-0.5deg) translateY(-100%);
        }

        20%,
        80% {
          transform: rotate(0.6deg) translateY(-100%);
          -ms-transform: rotate(0.6deg) translateY(-100%);
          -webkit-transform: rotate(0.6deg) translateY(-100%);
          -moz-transform: rotate(0.6deg) translateY(-100%);
          -o-transform: rotate(0.6deg) translateY(-100%);
        }

        30%,
        50%,
        70% {
          transform: rotate(-0.5deg) translateY(-100%);
          -ms-transform: rotate(-0.5deg) translateY(-100%);
          -webkit-transform: rotate(-0.5deg) translateY(-100%);
          -moz-transform: rotate(-0.5deg) translateY(-100%);
          -o-transform: rotate(-0.5deg) translateY(-100%);
        }

        40%,
        60% {
          transform: rotate(0.6deg) translateY(-100%);
          -ms-transform: rotate(0.6deg) translateY(-100%);
          -webkit-transform: rotate(0.6deg) translateY(-100%);
          -moz-transform: rotate(0.6deg) translateY(-100%);
          -o-transform: rotate(0.6deg) translateY(-100%);
        }
      }
    }

    // position arrow xl
    @media screen and (max-width: 1200px) {
      .svg-label1 {
        transform: translate(220px, -100px) rotate(-15deg);
        -ms-transform: translate(220px, -100px) rotate(-15deg);
        -webkit-transform: translate(220px, -100px) rotate(-15deg);
        -moz-transform: translate(220px, -100px) rotate(-15deg);
        -o-transform: translate(220px, -100px) rotate(-15deg);
      }
      .svg-label1 {
        height: 190px;
        width: 210px;
      }
      .svg-label1 path {
        stroke-width: 1.2;
      }
      .svg-label2 path {
        stroke-width: 2;
      }
      .svg-label2 {
        width: 160px;
        transform: translate(195px, -55px) rotate(20deg);
        -ms-transform: translate(195px, -55px) rotate(20deg);
        -webkit-transform: translate(195px, -55px) rotate(20deg);
        -moz-transform: translate(195px, -55px) rotate(20deg);
        -o-transform: translate(195px, -55px) rotate(20deg);
      }
      .svg-label2 path {
        stroke-width: 3;
      }
      .svg-label3 {
        width: 180px;
        transform: translate(-220px, -50px) rotate(-5deg);
        -ms-transform: translate(-220px, -50px) rotate(-5deg);
        -webkit-transform: translate(-220px, -50px) rotate(-5deg);
        -moz-transform: translate(-220px, -50px) rotate(-5deg);
        -o-transform: translate(-220px, -50px) rotate(-5deg);
      }
      .svg-label3 path {
        stroke-width: 2.7;
      }
      .svg-label4 {
        width: 245px;
        transform: translate(-220px, 40px) rotate(-25deg);
        -ms-transform: translate(-220px, 40px) rotate(-25deg);
        -webkit-transform: translate(-220px, 40px) rotate(-25deg);
        -moz-transform: translate(-220px, 40px) rotate(-25deg);
        -o-transform: translate(-220px, 40px) rotate(-25deg);
      }
      .svg-label4 path {
        stroke-width: 2.4;
      }
      .svg-label5 {
        width: 220px;
        transform: translate(215px, -165px) rotate(12deg);
        -ms-transform: translate(215px, -165px) rotate(12deg);
        -webkit-transform: translate(215px, -165px) rotate(12deg);
        -moz-transform: translate(215px, -165px) rotate(12deg);
        -o-transform: translate(215px, -165px) rotate(12deg);
      }
      .svg-label5 path {
        stroke-width: 3;
      }
      .svg-label6 {
        width: 155px;
        transform: translate(-225px, -70px) rotate(-25deg);
        -ms-transform: translate(-225px, -70px) rotate(-25deg);
        -webkit-transform: translate(-225px, -70px) rotate(-25deg);
        -moz-transform: translate(-225px, -70px) rotate(-25deg);
        -o-transform: translate(-225px, -70px) rotate(-25deg);
      }
      .svg-label6 path {
        stroke-width: 2.8;
      }
      .svg-label7 {
        width: 170px;
        transform: translate(100px, -120px);
        -ms-transform: translate(100px, -120px);
        -webkit-transform: translate(100px, -120px);
        -moz-transform: translate(100px, -120px);
        -o-transform: translate(100px, -120px);
      }
      .svg-label7 path {
        stroke-width: 3.2;
      }
      .svg-label8 {
        width: 243px;
        transform: translate(-230px, -175px) rotate(-10deg);
        -ms-transform: translate(-230px, -175px) rotate(-10deg);
        -webkit-transform: translate(-230px, -175px) rotate(-10deg);
        -moz-transform: translate(-230px, -175px) rotate(-10deg);
        -o-transform: translate(-230px, -175px) rotate(-10deg);
      }
      .svg-label8 path {
        stroke-width: 3;
      }

      .svg-label2-2 {
        width: 160px;
        transform: translate(200px, -45px) rotate(25deg);
        -ms-transform: translate(200px, -45px) rotate(25deg);
        -webkit-transform: translate(200px, -45px) rotate(25deg);
        -moz-transform: translate(200px, -45px) rotate(25deg);
        -o-transform: translate(200px, -45px) rotate(25deg);
      }
      .svg-label2-2 path {
        stroke-width: 3;
      }
      .svg-label3-2 {
        width: 100px;
        transform: translate(-220px, -110px) rotate(30deg);
        -ms-transform: translate(-220px, -110px) rotate(30deg);
        -webkit-transform: translate(-220px, -110px) rotate(30deg);
        -moz-transform: translate(-220px, -110px) rotate(30deg);
        -o-transform: translate(-220px, -110px) rotate(30deg);
      }
      .svg-label3-2 path {
        stroke-width: 4.5;
      }
      .svg-label6-2 {
        width: 130px;
        transform: translate(-210px, -100px) rotate(-5deg);
        -ms-transform: translate(-210px, -100px) rotate(-5deg);
        -webkit-transform: translate(-210px, -100px) rotate(-5deg);
        -moz-transform: translate(-210px, -100px) rotate(-5deg);
        -o-transform: translate(-210px, -100px) rotate(-5deg);
      }
      .svg-label6-2 path {
        stroke-width: 3.5;
      }
      .svg-label7-2 {
        width: 210px;
        transform: translate(100px, -130px);
        -ms-transform: translate(100px, -130px);
        -webkit-transform: translate(100px, -130px);
        -moz-transform: translate(100px, -130px);
        -o-transform: translate(100px, -130px);
      }
      .svg-label7-2 path {
        stroke-width: 2.8;
      }
      .svg-label8-2 {
        width: 180px;
        transform: translate(-215px, -180px) rotate(-10deg);
        -ms-transform: translate(-215px, -180px) rotate(-10deg);
        -webkit-transform: translate(-215px, -180px) rotate(-10deg);
        -moz-transform: translate(-215px, -180px) rotate(-10deg);
        -o-transform: translate(-215px, -180px) rotate(-10deg);
      }
      .svg-label8-2 path {
        stroke-width: 3.5;
      }
    }

    // position label & arrow lg
    @media screen and (max-width: 992px) {
      .label {
        font-size: 17px;
        width: 170px;
        height: 35px;
      }

      .colImg img {
        width: 600px !important;
        transform: translate(0, -100%);
      }

      .svg-label1 {
        transform: translate(180px, -90px) rotate(-30deg);
      }
      .svg-label1 {
        height: 190px;
        width: 50px;
      }
      .svg-label1 path {
        stroke-width: 5;
      }
      .svg-label2 {
        width: 105px;
        transform: translate(180px, -75px) rotate(20deg);
      }
      .svg-label2 path {
        stroke-width: 4.5;
      }
      .svg-label3 {
        width: 135px;
        transform: translate(-190px, -75px) rotate(-5deg);
      }
      .svg-label3 path {
        stroke-width: 4.5;
      }
      .svg-label4 {
        width: 170px;
        transform: translate(-180px, 20px) rotate(-25deg);
      }
      .svg-label4 path {
        stroke-width: 4.5;
      }
      .svg-label5 {
        width: 135px;
        transform: translate(185px, -165px) rotate(4deg);
      }
      .svg-label5 path {
        stroke-width: 5;
      }
      .svg-label6 {
        width: 130px;
        transform: translate(-180px, -70px) rotate(-25deg);
      }
      .svg-label6 path {
        stroke-width: 3.5;
      }
      .svg-label7 {
        width: 140px;
        transform: translate(100px, -120px);
      }
      .svg-label7 path {
        stroke-width: 4.3;
      }
      .svg-label8 {
        width: 175px;
        transform: translate(-180px, -180px) rotate(-5deg);
      }
      .svg-label8 path {
        stroke-width: 4.3;
      }
      .svg-label9 {
        width: 175px;
        transform: translate(-180px, -20px) rotate(-20deg);
      }
      .svg-label9 path {
        stroke-width: 2.6;
      }

      .svg-label2-2 {
        width: 120px;
        transform: translate(170px, -70px) rotate(25deg);
      }
      .svg-label2-2 path {
        stroke-width: 4;
      }
      .svg-label3-2 {
        width: 100px;
        transform: translate(-190px, -130px) rotate(40deg);
      }
      .svg-label3-2 path {
        stroke-width: 4.5;
      }
      .svg-label6-2 {
        width: 100px;
        transform: translate(-175px, -100px) rotate(-5deg);
      }
      .svg-label6-2 path {
        stroke-width: 4;
      }
      .svg-label7-2 {
        width: 150px;
        transform: translate(100px, -130px);
      }
      .svg-label7-2 path {
        stroke-width: 4;
      }
      .svg-label8-2 {
        width: 135px;
        transform: translate(-170px, -190px) rotate(10deg);
      }
      .svg-label8-2 path {
        stroke-width: 5;
      }
    }

    // position label & arrow md
    @media screen and (max-width: 768px) {
      .label {
        font-size: 15px;
        width: 155px;
        height: 35px;
      }

      .colImg img {
        width: 400px !important;
        transform: translate(0, -100%);
      }

      .svg-label1 {
        transform: translate(170px, -90px) rotate(-30deg);
      }
      .svg-label1 {
        height: 190px;
        width: 80px;
      }
      .svg-label1 path {
        stroke-width: 3.5;
      }
      .svg-label2 {
        width: 85px;
        transform: translate(160px, -95px) rotate(0deg);
      }
      .svg-label2 path {
        stroke-width: 5;
      }
      .svg-label3 {
        width: 75px;
        transform: translate(-160px, -98px) rotate(5deg);
      }
      .svg-label3 path {
        stroke-width: 6;
      }
      .svg-label4 {
        width: 75px;
        transform: translate(-160px, 5px) rotate(-25deg);
      }
      .svg-label4 path {
        stroke-width: 7;
      }
      .svg-label5 {
        width: 115px;
        transform: translate(150px, -195px) rotate(-20deg);
      }
      .svg-label5 path {
        stroke-width: 6;
      }
      .svg-label6 {
        width: 95px;
        transform: translate(-150px, -110px) rotate(20deg);
      }
      .svg-label6 path {
        stroke-width: 5;
      }
      .svg-label7 {
        width: 170px;
        transform: translate(90px, -130px) rotate(-9deg);
      }
      .svg-label7 path {
        stroke-width: 3.5;
      }
      .svg-label8 {
        width: 155px;
        transform: translate(-130px, -230px) rotate(40deg);
      }
      .svg-label8 path {
        stroke-width: 4.3;
      }
      .svg-label9 {
        width: 105px;
        transform: translate(-170px, -70px) rotate(20deg);
      }
      .svg-label9 path {
        stroke-width: 2.6;
      }

      .svg-label2-2 {
        width: 90px;
        transform: translate(157px, -95px) rotate(10deg);
      }
      .svg-label2-2 path {
        stroke-width: 6;
      }
      .svg-label3-2 {
        width: 100px;
        transform: translate(-150px, -150px) rotate(70deg);
      }
      .svg-label3-2 path {
        stroke-width: 4.5;
      }
      .svg-label6-2 {
        width: 90px;
        transform: translate(-135px, -120px) rotate(45deg);
      }
      .svg-label6-2 path {
        stroke-width: 6;
      }
      .svg-label7-2 {
        width: 190px;
        transform: translate(50px, -160px) rotate(-20deg);
      }
      .svg-label7-2 path {
        stroke-width: 4;
      }
      .svg-label8-2 {
        width: 155px;
        transform: translate(-105px, -230px) rotate(50deg);
      }
      .svg-label8-2 path {
        stroke-width: 5;
      }
    }

    // position label & arrow xs
    @media screen and (max-width: 576px) {
      #container1 {
        padding-top: 10vw;
      }

      // gestion display devices
      #row-label-screen,
      #row-images-screen {
        display: none !important;
      }
      #row-label-screen-2,
      #row-images-screen-2 {
        display: none !important;
      }
      #row-label-phone,
      #row-images-phone {
        display: flex !important;
      }
      .svg {
        display: none;
      }
      .row-label {
        display: none !important;
      }

      .svg-last-row {
        transform: translate(0, -10px) !important;
      }
      .label {
        color: #fff;
        font-family: "AnotherShabby";
        font-size: 17px;
        background-image: url(../Images/scotch2.png);
        background-size: 100% 100%;
        width: 180px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 300;
        cursor: pointer;
      }

      .label-clitoris-phone {
        color: $rose-fonce;
        font-family: "LearningCurve";
        font-size: 35px;
        line-height: 0.7em;
        text-align: center;
        transform: translate(-5px, -20px) rotate(-12deg);
        white-space: nowrap;
      }

      #svg-clitoris-phone {
        transform: translateY(-30px);
      }

      .p-call {
        font-family: "CutiveMono";
      }
    }

    //raz class
    .is-visible {
      display: flex;
    }
    .is-invisible {
      display: none !important;
    }
    #row-images-phone,
    #row-label-phone {
      display: none;
    }
    .label-hide {
      visibility: hidden;
    }

    #label-discover-vagin,
    #label-discover-clito {
      cursor: pointer;
    }
  }

  #container2 {
    //tire "Les règles, c’est quoi ?"
    .titre {
      font-family: LearningCurve;
      font-size: 40px;
      color: #fff;
    }

    //col avec texte et paper
    .col-texte {
      background-image: url("../Images/paper7.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 110px 40px 60px 110px;
      p:nth-child(1) {
        font-family: cutiveMono;
        font-size: 11px;
      }
    }

    // gestion paper et texte
    @media screen and (max-width: 1200px) {
      .col-texte {
        padding: 115px 20px 30px 110px;
        background-size: cover;
      }
    }

    @media screen and (max-width: 992px) {
      .col-texte {
        padding: 120px 70px 30px 120px;
        margin-top: 80px;
      }
      .carousel {
        margin-top: 80px;
      }
    }

    @media screen and (max-width: 768px) {
      .col-texte {
        padding: 110px 40px 30px 110px;
      }
    }

    @media screen and (max-width: 576px) {
      .col-texte {
        padding: 110px 40px 30px 40px;
        background-size: 145% 100%;
        background-position: center;
      }
    }

    @media screen and (max-height: 900px) and (min-width: 1000px) {
      .col-titre {
        transform: translateY(70px);
      }
    }

    @media screen and (max-height: 733px) and (min-width: 1000px) {
      .col-titre {
        transform: translateY(100px);
      }
    }

    //box avec texte et box 'title check"
    .box-tc {
      font-family: cutiveMono;
      margin-top: 50px;
      p {
        font-size: 11px;
        line-height: 18px;
      }

      // titre et check box
      .box-titleCheck {
        display: flex;
        flex-direction: row;

        //titre
        p {
          font-size: 15px;
          margin-left: 10px;
        }

        // checkbox
        div {
          width: 15px;
          height: 15px;
          border: 1.5px solid rgba(0, 0, 0, 0.719);
        }
      }

      // check
      svg {
        position: absolute;
        transform: translate(-1px, -7px);
      }
      svg path {
        stroke-width: 1.5px;
      }
    }

    .date {
      text-align: center;
      font-family: "AnotherShabby";
      font-size: 45px;
      color: #eee;
      span {
        font-family: "AlertNotice";
      }
    }

    .effet {
      font-family: CutiveMono;
      color: white;
      font-size: 11px;
      text-align: center;
      width: 90%;
      height: 82px;
      margin: auto;
    }

    .box-cup-indicator svg {
      margin: 6px;
    }

    // carousel
    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }
    .carousel-indicators {
      transform: translateY(70px);
    }
    .carousel-indicators li {
      width: 23px;
      height: 36px;
      border: none;
      background-image: url(../Images/blood-empty.svg);
      background-size: contain;
      background-color: transparent;
      opacity: 1;
      margin: 0 6px;
    }

    li.active {
      background-image: url(../Images/blood-full.svg) !important;
      background-color: transparent;
      background-size: contain;
      opacity: 1;
    }

    .carousel-item div img {
      width: 40%;
    }
  }

  #container3 {
    // titre "les regles, comment..."
    .titre {
      font-family: LearningCurve;
      font-size: 40px;
      color: #fff;
    }

    // col avec texte & paper
    .col1 {
      background-image: url(../Images/paper6.png);
      background-size: 100% auto;
    }

    //titre de la frise
    .titre2 {
      width: 100%;
      font-family: "AnotherShabby";
      font-size: 35px;
      color: #000;
      text-align: center;
    }

    // titres des phases
    .titre3 {
      font-family: CutiveMono;
      font-size: 15px;
      font-weight: bold;
      color: #000;
    }

    //definiton des phases
    .def {
      font-family: CutiveMono;
      font-size: 11px;
      color: #000;
    }

    // div parent de ".box-text"
    .box-phases {
      width: 85%;
      text-align: center;
      padding: 0 20px;
    }

    // div avec titres3 , def , svg
    .box-text {
      margin: 0 5px;
    }

    .img-frise {
      width: 100%;
    }

    // gestion des positions et typo
    @media screen and (max-width: 1200px) {
      .box-phases {
        width: 90%;
      }
      .box-phases {
        margin: 0 15px;
      }
    }
    @media screen and (max-width: 768px) {
      .box-phases {
        padding: 0;
        width: 100%;
      }
      .col1 {
        padding: 0 30px 0 !important;
      }
    }
    @media screen and (max-width: 576px) {
      .titre {
        font-size: 35px;
      }
      .svg-frise-4 {
        width: 36px;
        margin-top: -20px;
      }
      .svg-frise-2 {
        width: 60px;
        margin-top: -30px;
      }
    }
    @media screen and (max-width: 416px) {
      .img-frise {
        width: 90%;
        margin-left: 5%;
      }
      .box-phases {
        padding: 0 !important;
        margin: 0;
      }
      .col1 {
        padding: 20px 0 !important;
      }
      .titre3 {
        font-size: 13px;
      }
    }

    @media screen and (max-height: 735px) {
      .col-titre {
        transform: translateY(20px);
      }
      .col-picto {
        transform: translateY(-40px);
        margin: 0;
      }
    }
  }

  #container4 {
    // titre "My Dear Vagina c’est aussi ..."
    .titre {
      font-family: LearningCurve;
      font-size: 40px;
      color: #000;
    }

    //init des affichages phone
    #row-images-phone,
    #row-label-phone {
      display: none !important;
    }
    .other {
      font-family: cutiveMono;
    }

    //texte label (liste des thémes)
    .label {
      color: #fff;
      font-family: "AnotherShabby";
      font-size: 19px;
      background-image: url(../Images/scotch2.png);
      background-size: 100% 100%;
      width: 200px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 300;
    }
    #row-label-screen {
      width: 100%;
    }
    #row-images-screen {
      position: absolute;
      bottom: 0;
    }

    //gestion screen => phone & position
    @media screen and (max-width: 576px) {
      #hand-img {
        width: 50%;
      }

      //texte label (liste des thémes)
      .label {
        font-size: 17px;
        width: 170px;
        height: 35px;
      }

      // texte "Et bien d’autres !"
      .other {
        font-family: cutiveMono;
        font-size: 14px;
      }

      .svg {
        width: 90%;
      }
      .titre {
        font-size: 35px;
      }
      #row-images-phone div {
        display: flex;
        justify-content: center;
      }
      #row-images-phone div img {
        width: 80%;
      }
    }
    @media screen and (max-width: 992px) {
      #row-images-screen,
      #row-label-screen {
        display: none !important;
      }
      #row-images-phone,
      #row-label-phone {
        display: flex !important;
      }

      .other {
        font-family: cutiveMono;
      }
    }
    @media screen and (max-width: 1200px) {
      #hand-img {
        width: 80%;
      }
    }
    @media screen and (max-height: 794px) {
      .col-titre {
        transform: translateY(30px);
        -ms-transform: translateY(30px);
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -o-transform: translateY(30px);
      }
      #hand-img {
        width: 95%;
      }
    }
    @media screen and (max-height: 723px) {
      .col-titre {
        transform: translateY(55px);
      }
      #hand-img {
        width: 85%;
      }
    }
  }

  #container5 {
    h3 {
      font-family: "AnotherShabby";
      font-size: 40px;
      margin: 0;
    }
    p {
      font-family: "CutiveMono";
      font-size: 13px;
      line-height: 15px;
    }

    // col avec paper et texte
    .coll-text {
      background-image: url("../Images/paper3.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding: 3% 3% 3% 3%;
    }

    .box {
      width: 90%;
    }

    //taille de box => texte, dans paper
    @media screen and (max-width: 992px) {
      .box {
        width: 80%;
        margin: 20% 0;
      }
      .coll-text {
        background-size: 100% 100%;
        padding: 10% 5% 5% 5%;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }

    // carousel
    .carousel,
    .slide {
      width: 100%;
    }

    // icon de control [NEXT , PREV]
    .carousel-control-next,
    .carousel-control-prev {
      width: 41px;
      height: 41px;
      background-color: #e4737f;
      border-radius: 100%;
      z-index: 9999;
      top: 108% !important;
      bottom: 0 !important;
      opacity: 1 !important;
    }
    .carousel-control-next {
      right: 17%;
    }
    .carousel-control-prev {
      left: 17%;
    }

    // backgound-image dans incon de control
    .carousel-control-next-icon {
      background-image: url("../Images/polygon-right.png");
      width: 18px;
      height: 18px;
      margin-left: 3px;
    }
    .carousel-control-prev-icon {
      background-image: url("../Images/polygon-left.png");
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }

    // rectangle indication slide
    .carousel-indicators {
      display: none;
    }
    .img-slide {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    // texte "Aperçu de l ‘expo"
    .under-carousel {
      color: white;
      font-size: 40px;
      font-family: LearningCurve;
      transform: translateY(300%);

      -ms-transform: translateY(300%);
      -webkit-transform: translateY(300%);
      -moz-transform: translateY(300%);
      -o-transform: translateY(300%);
    }

    // gestion de la taille et position
    @media screen and (max-width: 1200px) {
      .under-carousel {
        font-size: 35px;
        transform: translateY(280%);

        -ms-transform: translateY(280%);
        -webkit-transform: translateY(280%);
        -moz-transform: translateY(280%);
        -o-transform: translateY(280%);
      }
    }
    @media screen and (max-width: 520px) {
      .under-carousel {
        transform: translateY(220%);

        -ms-transform: translateY(220%);
        -webkit-transform: translateY(220%);
        -moz-transform: translateY(220%);
        -o-transform: translateY(220%);
      }
      .carousel-control-next {
        right: 0;
      }
      .carousel-control-prev {
        left: 0;
      }
    }
  }

  #container6 {
    // arc sous l'image
    z-index: 2;

    // [screen] image sur toute hauteur de la section
    img {
      width: auto;
      height: calc(100vh - 86px);
    }

    // [phone] image sur toute largeur de la section
    @media screen and (max-width: 992px) {
      min-width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  //gestion des picto absolute
  #slide {
    //picto absolute
    .picto-tdf-abs {
      z-index: 200;
      position: absolute;
      width: 50px;
      height: 50px;
      top: 10%;
      transform: translateX(-200%);
    }
    .top-90 {
      top: 90%;
      transform: translateX(-200%);
    }
    .top-70 {
      top: 70%;
      transform: translateX(-200%);
    }
    .translate-n200 {
      transform: translate(-200%);
    }
    .translate-20 {
      transform: translateX(200%);
    }

    @media screen and (max-width: 992px) {
      .picto-tdf-abs {
        display: none !important;
      }
    }
  }
}

.newCol{
  display: flex;
    flex-direction: column;
    text-align: right;
}