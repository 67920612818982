@import "./ColorFont.scss";

a, a:hover, a:visited{
  color: white;
  text-decoration: none;
}

#main-Homepage {
  // couleur des sections & ::before
  #slide:nth-child(1) {
    background-color: $rose-pale3;
  }
  #slide:nth-child(1)::before {
    display: none;
  }
  #slide:nth-child(2),
  #slide:nth-child(2)::before {
    background-color: $vert;
  }
  #slide:nth-child(3),
  #slide:nth-child(3)::before {
    background-color: $rose;
  }
  #slide:nth-child(4),
  #slide:nth-child(4)::before {
    background-color: $beige;
  }
  #slide:nth-child(5),
  #slide:nth-child(5)::before {
    background-color: $orange;
  }
  #slide:nth-child(6),
  #slide:nth-child(6)::before {
    background-color: #372821;
  }

  // btn "Bientôt dispo"
  .btn {
    background-color: $rose-fonce;
    border-radius: 100px;
    border: none;
    padding: 0.7em 1.2em;
    font-family: "AnotherShabby";
    font-size: 20px;
    transform: rotate(-4deg);
    transition: 0.2s ease;
  }

  .btn-primary.focus,
  .btn-primary:focus {
    box-shadow: none;
  }
  .btn:hover {
    transform: scale(1.1);
    background-color: $rose-fonce;
  }

  // coll avec paper & texte (gestion commun background-image)
  .coll-text {
    background-repeat: no-repeat;
    background-position: center;
  }

  // gestion des typo
  h1 {
    font-family: "AnotherShabby";
    font-size: 40px;
    margin: 0;
  }
  h2 {
    font-family: "LearningCurve";
    font-size: 40px;
    margin: 0;
    line-height: 32px;
  }
  h3 {
    font-family: "AnotherShabby";
    font-size: 40px;
    margin: 0;
  }
  p {
    font-family: "CutiveMono";
    font-size: 13px;
    line-height: 15px;
  }

  .coll-text {
    z-index: 10;
  }

  #container1 {
    // col avec texte & paper
    .coll-text {
      background-image: url("../Images/paper1.png");
      background-size: auto 100%;
    }

    // div avec texte dans ".col-text"
    .box {
      width: 60%;
      padding-top: 10%;
    }

    //taille de box => texte, dans paper
    @media screen and (max-width: 992px) {
      .box {
        width: 70%;
        margin: 20% 0;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }
  }

  #container2 {
    // col avec texte & paper
    .coll-text {
      background-image: url("../Images/paper2.png");
      background-size: auto 80%;
    }

    .coll-text p {
      padding: 0 10px;
    }

    // div avec texte dans ".col-text"
    .box {
      width: 90%;
    }

    //taille de box => texte, dans paper
    @media screen and (max-width: 992px) {
      .box {
        width: 80%;
        margin: 10% 0;
      }
      .coll-text {
        background-size: auto 100%;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }
  }

  #container3 {
    // col avec texte & paper
    .coll-text {
      background-image: url("../Images/paper3.png");
      background-size: 100% auto;
    }

    // div avec texte dans ".col-text"
    .box {
      width: 90%;
      h3 {
        margin: 0;
      }
    }

    //taille de box => texte, dans paper
    @media screen and (min-width: 1200px) {
      .box {
        width: 75% !important;
        margin: 15% 0 0 0 !important;
      }
      .coll-text {
        background-size: auto 85%;
      }
    }
    @media screen and (min-width: 992px) {
      .box {
        width: 80%;
        margin: 20% 0 0 0;
      }
    }
    @media screen and (max-width: 992px) {
      .box {
        width: 80%;
        margin: 20% 0;
        h3 {
          margin-top: 50px;
        }
      }
      .coll-text {
        background-size: auto 100%;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }
  }

  #container4 {
    // col avec texte & paper
    .coll-text {
      background-image: url("../Images/paper4.png");
      background-size: 100% auto;
    }

    // col avec paper => scotch
    .scotch::before {
      content: "";
      width: 200px;
      height: 100px;
      background-image: url(../Images/scotch1.png);
      background-position: center;
      background-repeat: repeat;
      position: absolute;
      transform: translateY(-190%);
    }

    // div avec texte dans ".col-text"
    .box {
      width: 90%;
    }

    //taille de box => texte, dans paper
    @media screen and (max-width: 992px) {
      .box {
        width: 80%;
        margin: 20% 0;
      }
      .coll-text {
        background-size: auto 100%;
      }
      .scotch::before {
        transform: translateY(-190%) !important;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }

    @media screen and (max-width: 1200px) {
      .scotch::before {
        transform: translateY(-150%);
      }
    }
  }

  #container5 {
    // col avec paper & texte
    .coll-text {
      background-image: url("../Images/paper5.png");
      background-size: 100% auto;
    }

    // div avec texte dans ".col-text"
    .box {
      width: 90%;
    }

    //taille de box => texte, dans paper
    @media screen and (max-width: 992px) {
      .box {
        width: 80%;
        margin: 20% 0;
      }
      .coll-text {
        background-size: auto 100%;
      }
    }
    @media screen and (max-width: 500px) {
      .box {
        width: 100%;
        margin: 20% 0;
      }
    }
  }

  #container6 {
    // arc sous l'image
    z-index: 2;

    // [screen] image sur toute hauteur de la section
    img {
      width: auto;
      height: calc(100vh - 86px);
    }

    // [phone] image sur toute largeur de la section
    @media screen and (max-width: 992px) {
      min-width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  //gestion des picto absolute
  #slide {
    //picto absolute
    .picto-tdf-abs {
      z-index: 200;
      position: absolute;
      width: 50px;
      height: 50px;
      top: 10%;
      transform: translateX(-200%);
    }
    .top-90 {
      top: 90%;
      transform: translateX(-200%);
    }
    .top-80 {
      top: 80%;
    }
    .top-70 {
      top: 70%;
      transform: translateX(-200%);
    }
    .translate-n200 {
      transform: translate(-200%);
    }
    .translate-20 {
      transform: translateX(200%);
    }
    .translate-50 {
      transform: translateX(550%);
    }
    .translate-n300 {
      transform: translate(-300%);
    }

    @media screen and (max-width: 992px) {
      .picto-tdf-abs {
        display: none !important;
      }
    }
  }
}
